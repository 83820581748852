import clsx from "clsx";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
// import { TooltipAvatarStatus } from "../tooltips/Tooltip";

function TwoColumnLayout(
  {
    children,
    sideTitle,
    sideContents,
    side = "right",
    mainWidthClass = "3/4",
    asideWidthClass = "1/4",
    breakpointClass = "2xl",
    component = "div",
    mainRef,
    sideRef,
    wrapperClasses,
    mainClasses,
    sideClasses,
    disableBreakCheck,
  }: {
    children: any;
    sideTitle: string;
    sideContents: any;
    side?: string;
    asideWidthClass?: string;
    breakpointClass?: string;
    mainWidthClass?: string;
    component?: string | React.ComponentType<any>;
    mainRef?: any;
    sideRef?: any;
    wrapperClasses?: string;
    mainClasses?: string;
    sideClasses?: string;
    disableBreakCheck?: boolean;
  },
  ref
) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const sizes = usePagesizeSelectorContext((state) => state.sizes);
  const width = usePagesizeSelectorContext((state) => state.width);

  const isAtBreakPoint = useMemo(() => {
    return sizes[breakpointClass];
  }, [sizes, breakpointClass]);

  useImperativeHandle(ref, () => ({ setSidebarOpen }));

  // if (!width) {
  //   return null;
  // }

  return (
    <div className={clsx("lg:overflow-hidden", wrapperClasses)}>
      <nav className={clsx(sideClasses)} ref={sideRef}>
        {sideContents}
      </nav>
      <main className={clsx(mainClasses)} ref={mainRef}>
        {children}
      </main>
    </div>
  );
}

export default forwardRef(TwoColumnLayout);
